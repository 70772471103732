<template>
  <div>
    <PageTitle v-bind:title="'Scriptoria'" />

    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col>
          <b-button variant="primary" size="sm" :disabled="!$store.getters.checkRole('legam.bib.scriptorium.add')" class="float-right mb-4" v-b-modal.modal-new-writingtype>
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new scriptorium
          </b-button>
          <b-modal id="modal-new-writingtype" title="New Scriptorium" ok-title="Save" @ok="saveScriptorium" size="lg">
            <ScriptoriumNewEdit />
          </b-modal>
          <LegamDataTables
            v-if="config.editable"
            v-bind="$data"
            @click="tableAction"
            @selectChanged="selectChanged"
          />
          <LegamDataTablesClientSide v-else v-bind="$data" />
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal-edit-scriptorium"
      title="Edit Scriptorium"
      ok-title="Save"
      @ok="saveScriptorium"
      size="lg"
    >
      <ScriptoriumNewEdit
        :key="scriptoriumModelKey"
        :scriptorium="scriptoriumModel"
      />
    </b-modal>

    <div class="merge-float" v-if="selected.length">
      <b-badge href="#" variant="info" class="mr-1" v-for="scriptorium in selected" :key="scriptorium.id" @click="removeSelected(scriptorium)">
        <span v-html="scriptorium.abbreviation"></span>
        <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon>
      </b-badge>
      <span class="btn-legam-xxs btn-legam-xs btn-legam-blue" v-if="selected.length > 1" v-b-modal.modal-merge>
        <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
        Merge scriptoria
      </span>
      <span class="btn-legam-xxs btn-legam-xs" @click="selected = []">
        <b-icon icon="x" aria-hidden="true"></b-icon>
      </span>
      <b-modal id="modal-merge" title="Merge scriptoria" ok-title="Merge" @ok="merge" size="md">
        <b-badge href="#" variant="info" class="mr-1" v-for="scriptorium in selected" :key="'modal' + scriptorium.id" @click="removeSelected(scriptorium)">
          <span v-html="scriptorium.abbreviation"></span>
          <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon>
        </b-badge>
        <b-form-group
          code="main-entity-grp"
          label="Merge to"
          label-for="main-entity"
          class="mt-3"
          description="This scriptorium will keep all it's data (other scriptoria will move their data to this scriptorium and then disappear)"
        >
          <b-form-select
            v-model="mainEntityId"
            :options="selected.map(scriptorium => {
              return {
                'text': scriptorium.abbreviation,
                'value': scriptorium.id,
              }
            })"
          ></b-form-select>
        </b-form-group>
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 150px;
  }

  .merge-float {
    position: fixed;
    bottom: 15px;
    left: 20px;
    background-color: #bebebe9a;
    padding: 3px 8px 5px;
    border-radius: 3px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  // import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import tdColAction from "@/components/tdColAction";
  import tdColSafeHTML from "@/components/tdColSafeHTML"
  import ScriptoriumNewEdit from "@/components/Bibliography/ScriptoriumNewEdit"
  import HelpStore from '@/store/helpstore.js'
  import tdColUUID from "@/components/tdColUUID"
  import config from '@/config.js'

  export default {
    name: 'ScriptoriaList',
    data(){
      return {
        columns: [
          { title: 'Id', field: 'id', sortable: true, searchable: true, tdComp: tdColUUID },
          { title: 'counts', field: 'manuscriptCounts', sortable: true },
          { title: this.$t('Abbreviation'), field: 'abbreviation', sortable: true, searchable: true },
          { title: this.$t('FullDescription'), field: 'name', sortable: true, searchable: true },
          { title: this.$t('Town'), field: 'town', sortable: true, searchable: true },
          { title: this.$t('Dep'), field: 'department', sortable: true, searchable: true },
          { title: this.$t('Scripta'), field: 'scripta', sortable: true, searchable: true },
          { title: 'Comment', field: 'comment', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Action', field: 'id', tdComp: tdColAction, thClass: 'tdAction',
            transformData: function (row) {
              return [
                { text: null, icon: 'list-ul', id: row.id, to: {
                  name: 'ScriptoriumDetails',
                  params: {
                    id: row.id
                  }}
                },
                {
                  icon: 'trash',
                  text: ' ',
                  dataId: JSON.stringify({
                    action: 'delete',
                    id: row.id
                  })
                }
              ]
            }
          }
        ],
        columnsPI: [
          { title: this.$t('Abbreviation'), field: 'abbreviation', sortable: true, searchable: true },
          { title: this.$t('FullDescription'), field: 'name', sortable: true, searchable: true },
          { title: this.$t('Town'), field: 'town', sortable: true, searchable: true },
          { title: this.$t('Dep'), field: 'department', sortable: true, searchable: true },
          { title: this.$t('Scripta'), field: 'scripta', sortable: true, searchable: true },
          { title: 'Comment', field: 'comment', sortable: true, tdComp: tdColSafeHTML, searchable: true }
        ],
        url: '/scriptoriums/datatables',
        key: 1,
        selected: [],
        mainEntityId: null,
        searchableByColumn: true,
        scriptoriumModel: null,
        scriptoriumModelKey: 0,
        config: config,
      }
    },
    computed: {
      ...mapState({
        userSettings: state => state.user.settings
      }),
    },
    components: {
      PageTitle,
      LegamDataTables,
      LegamDataTablesClientSide,
      ScriptoriumNewEdit
    },
    methods: {
      saveScriptorium(){
        this.$store.dispatch('saveScriptorium', HelpStore.item).then(() => this.key++)
      },
      selectChanged(items){
        this.selected = items
        if (items.length){
          this.mainEntityId = items[0].id
        }
      },
      merge() {
        this.$store.dispatch('mergeScriptoria', {
          mainScriptoriumId: this.mainEntityId,
          scriptoriumIds: this.selected.filter(scriptorium => scriptorium.id != this.mainEntityId).map(scriptorium => scriptorium.id)
        }).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Scriptoria merged`
          })
          this.selected = []
          this.mainEntityId = null
          this.key++
        })
      },
      removeSelected(item) {
        this.selected = this.selected.filter(_item => item.id != _item.id)
      },
      tableAction(action) {
        action = JSON.parse(action)
        if (action.action == 'delete') {
          this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
            if (value){
              this.$store.dispatch('deleteScriptorium', {
                id: action.id
              }).then(() => {
              this.$store.dispatch('addNotification', {
                type: 'success',
                text: `Scriptorium deleted`
                })
              this.key++
              })
            }})
        } else if (action.action == 'edit') {
          this.scriptoriumModel = action.scriptorium
          this.scriptoriumModelKey++
          this.$bvModal.show('modal-edit-scriptorium')
        }
      },
    },
    watch: {
      "userSettings"(){
        this.key++
      }
    }
  }
</script>
