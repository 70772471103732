<template>
  <div>
    <b-form-group
      id="input-abbreviation-group"
      label="Abbreviation:"
      label-for="input-abbreviation"
    >
      <b-form-input
        id="input-abbreviation"
        v-model="model.abbreviation"
        placeholder="Abbreviation"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="input-name-group"
      label="Full Description:"
      label-for="input-name"
    >
      <b-form-input
        id="input-name"
        v-model="model.name"
        placeholder="Full Description"
      ></b-form-input>
    </b-form-group>

    <hr>

    <b-form-group
      id="input-location-group"
      label="Primary Location:"
      label-for="input-location"
    >
      <LocationSelect
        :location="model.location ? model.location : null"
        @select="updateLocation"
      />

    </b-form-group>

    <b-form-group
      id="input-comment-group"
      label="Comment:"
      label-for="input-comment"
    >
      <b-form-textarea
        id="input-comment"
        placeholder="Comment"
        v-model="model.comment"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<style scoped>
</style>

<script>
  import HelpStore from '@/store/helpstore.js'
  import LocationSelect from '@/components/Main/LocationSelect.vue'


  export default {
    name: 'ScriptoriumNewEdit',
    props: ['scriptorium'],
    data(){
      return {
        // TODO: fetch from API
        model: (this.scriptorium ? this.scriptorium : {
          abbreviation: "",
          name: "",
          location: "",
          unspecified: "",
          comment: ""
        })
      }
    },
    mounted() {
      HelpStore.item = this.model
    },
    components: {
      LocationSelect
    },
    methods: {
      updateLocation(location) {
        this.model.location = location
      }
    },
    updated(){
      HelpStore.item = this.model
    },
  }
</script>

