<template>
  <div class="locations" id="select-location">
    <Select2 @select="select($event)" :settings="selectConfig" ref="select2" tabindex="0" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'
  import $ from 'jquery'

  export default {
    name: 'LocationSelect',
    props: ['exclude', 'location'],
    components: {
      Select2
    },
    mounted() {
      if (this.location){
        this.$refs.select2.select2.append(
          `<option selected value="${this.location.id}">${this.location.display}</option>`
        ).trigger('change')
      }
    },
    computed: {
      selectConfig(){
        return {
          placeholder: "Select a location",
          allowClear: true,
          templateResult: this.formatOption,
          templateSelection: this.formatOption,
          width: '100%',
          dropdownParent: "#select-location",
          ajax: {
            delay: 250,
            url: config.apiUrl + '/locations/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              return {
                query: params.term
              }
            },
            processResults: (data) => {
              return {
                results: data.map(location => {
                  return {
                    id: location.id,
                    text: location.display,
                    location: location
                  }
                })
              }
            }
          }
        }
      }
    },
    methods: {
      select(event){
        this.$emit('select', event.selected ? event.location :  null)
      },
      formatOption (item) {
        let text = item.text.trim() ? item.text : item.id
        return $(`<span>${text}</span>`)
      }
    }
  }
</script>
